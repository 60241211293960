import { keyBy, map, omit, without, mapValues, groupBy } from 'lodash'
import logger from '../logger'

import {
  ASSIGN_EMPLOYEES_COMPLETE,
  ASSIGN_LICENSE_COMPLETE,
  CERTIFICATE_UPLOAD_COMPLETE,
  CERTIFICATE_UPDATE_COMPLETE,
  CREATE_EMPLOYEE_COMPLETE,
  CREATE_OWNER_COMPLETE,
  DELETE_SHOP_COMPLETE,
  CREATE_SHOP_COMPLETE,
  EDIT_SHOP_COMPLETE,
  DISTRIBUTOR_DATA_READY,
  FETCH_DISTRIBUTORS,
  ACTIVATE_EMPLOYEE_COMPLETE,
  CHANGE_DISTRIBUTOR,
  GENERATE_NEW_LICENSES,
  LOCK_SHOP_COMPLETE,
  CONFIGURE_SOCIAL_CONCEPT_COMPLETE,
  DICTIONARY_METADATA_READY,
  UPDATE_DISTRIBUTOR_FEATURES
} from './actions'

const apiToReducer = (items) => ({
  ids: map(items, 'id'),
  byId: keyBy(items, 'id')
})

const updateComplete = (type) => (state, payload) => ({
  ...state,
  [type]: {
    ...state[type],
    byId: {
      ...state[type].byId,
      ...keyBy(payload, 'id')
    }
  }
})

const updateSingleValue = (type, key) => (state, payload) => ({
  ...state,
  [type]: {
    ...state[type],
    byId: {
      ...state[type].byId,
      [payload.id]: {
        ...state[type].byId[payload.id],
        [key]: payload[key]
      }
    }
  }
})

const createComplete = (type) => (state, payload) => ({
  ...state,
  [type]: {
    ...state[type],
    byId: {
      ...state[type].byId,
      [payload.id]: payload
    },
    ids: [...state[type].ids, payload.id]
  }
})

const deleteShop = () => (state, payload) => ({
  ...state,
  shops: {
    ...state.shops,
    byId: omit(state.shops.byId, payload.id),
    ids: without(state.shops.ids, payload.id)
  },
  licenses: {
    ...state.licenses,
    byId: mapValues(state.licenses.byId, (license) =>
      license.shop === payload.id ? { ...license, shop: null } : license
    )
  }
})

const addNewLicenses = (state, payload) => ({
  ...state,
  licenses: {
    ...state.licenses,
    byId: { ...state.licenses.byId, ...apiToReducer(payload).byId },
    ids: [...state.licenses.ids, ...apiToReducer(payload).ids]
  }
})

const scansToReducer = (items) => ({
  ids: map(items, 'id'),
  byId: keyBy(items, 'id'),
  byShopId: mapValues(groupBy(items, 'shopId'), (item) => ({
    scans: item,
    scansByEmployeeId: groupBy(item, 'employeeId')
  })),
  byOwnerId: groupBy(items, 'ownerId')
})

const oasisApiCallsToReducer = (items) => ({
  ids: map(items, 'id'),
  byId: keyBy(items, 'id'),
  byShopId: mapValues(groupBy(items, 'shopId'), (item) => ({
    oasisApiCalls: item,
    oasisApiCallsByEmployeeId: groupBy(item, 'employeeId')
  })),
  byOwnerId: groupBy(items, 'ownerId')
})

const shopCreateComplete = createComplete('shops')
const editShopComplete = updateComplete('shops')
const ownerCreateComplete = createComplete('owners')
const employeeCreateComplete = createComplete('employees')
const deleteShopComplete = deleteShop()
const lockShopComplete = updateSingleValue('shops', 'locked')
const configureSocialConceptComplete = updateSingleValue('shops', 'socialConceptEnabled')

const licenseUpdateComplete = updateComplete('licenses')
const updateCertificateInDistributor = updateSingleValue(
  'distributors',
  'certificate'
)
const updateEmployeesInShop = updateSingleValue('shops', 'employees')
const activateEmployee = updateSingleValue('employees', 'activatedAt')
const updateFeatures = (state, payload) => {
  const distributorId = payload.distributors[0].id

  return {
    ...state,
    distributors: {
      ...state.distributors,
      byId: {
        ...state.distributors.byId,
        [distributorId]: {
          ...state.distributors.byId[distributorId],
          users: [{ ...state.distributors.byId[distributorId].users[0], features: payload.features }]
        }
      }
    }
  }
}

const backOfficeReducer = (state, { payload, type }) => {
  logger.debug(`backOfficeReducer type: ${type}`)
  logger.debug(payload)

  switch (type) {
    case FETCH_DISTRIBUTORS:
      return {
        ...state,
        // TODO: move active distributor ID to router?
        distributor: payload?.find(({ favourite }) => favourite).id,
        distributors: apiToReducer(payload)
      }

    case CHANGE_DISTRIBUTOR:
      return {
        ...state,
        distributor: payload
      }

    case DISTRIBUTOR_DATA_READY:
      return {
        ...state,
        shops: apiToReducer(payload.shops),
        employees: apiToReducer(payload.employees),
        licenses: apiToReducer(payload.licenses),
        owners: apiToReducer(payload.owners),
        scans: scansToReducer(payload.scans),
        schedules: apiToReducer(payload.schedules),
        oasisApiCalls: oasisApiCallsToReducer(payload.oasisApiCalls)
      }

    case DICTIONARY_METADATA_READY:
      return { ...state, metaData: payload }

    case CERTIFICATE_UPLOAD_COMPLETE:
    case CERTIFICATE_UPDATE_COMPLETE:
      return updateCertificateInDistributor(state, payload)

    case DELETE_SHOP_COMPLETE:
      return deleteShopComplete(state, payload)

    case CREATE_SHOP_COMPLETE:
      return shopCreateComplete(state, payload)

    case EDIT_SHOP_COMPLETE:
      // we need to wrap payload to brackets because updateComplete helper needs collection
      return editShopComplete(state, [payload])

    case CREATE_OWNER_COMPLETE:
      return ownerCreateComplete(state, payload)

    case CREATE_EMPLOYEE_COMPLETE:
      return employeeCreateComplete(state, payload)

    case ACTIVATE_EMPLOYEE_COMPLETE:
      return activateEmployee(state, payload)

    case ASSIGN_LICENSE_COMPLETE:
      return licenseUpdateComplete(state, payload)

    case ASSIGN_EMPLOYEES_COMPLETE:
      return updateEmployeesInShop(state, payload)

    case GENERATE_NEW_LICENSES:
      return addNewLicenses(state, payload)

    case LOCK_SHOP_COMPLETE:
      return lockShopComplete(state, payload)

    case CONFIGURE_SOCIAL_CONCEPT_COMPLETE:
      return configureSocialConceptComplete(state, payload)

    case UPDATE_DISTRIBUTOR_FEATURES:
      return updateFeatures(state, payload)

    default:
      return state
  }
}

export default backOfficeReducer
