import PropTypes from 'prop-types'
import React, { useState } from 'react'
import ModalScreen from '../../components/modals/modalComponents/ModalScreen'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useShop } from '../../components/shop/hooks'
import ModalConfirmation from './form/ModalConfirmation'
import logger from '../../logger'

const ConfigureSocialConcept = ({ onCancel = () => {}, onSubmit = () => {} }) => {
  const params = useParams()
  const shop = useShop(params.id)
  const { t } = useTranslation('oasisBackoffice')
  const [isLoading, setIsLoading] = useState(false)

  const handleSubmit = async () => {
    setIsLoading(true)
    try {
      await onSubmit({
        socialConceptEnabled: !shop.socialConceptEnabled
      })
    } catch (error) {
      // Error is handled by the parent component
      logger.error('Failed to configure social concept:', error)
    } finally {
      setIsLoading(false)
    }
  }

  const buttonLabel = shop.socialConceptEnabled ? 'buttonLabelDeactivate' : 'buttonLabelActivate'

  return (
    <ModalScreen
      openModal
      onClose={onCancel}
      title={t('actionDialogs.titleConfigureSK', { shop: shop.name })}
      withForm
    >
      <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
        <ModalConfirmation
          onSubmit={handleSubmit}
          onCancel={onCancel}
          submitLabel={buttonLabel}
          loading={isLoading}
        />
      </div>
    </ModalScreen>
  )
}

ConfigureSocialConcept.propTypes = {
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func
}

export default ConfigureSocialConcept
