import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

const ActionsMenu = ({ actions = [], onClick = () => {} }) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const handleOnClick = (event) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const handleOnClose = (event) => {
    event.stopPropagation()
    setAnchorEl(null)
  }

  const { t } = useTranslation('oasisBackoffice')

  return (
    <>
      <Button
        size='small'
        variant='text'
        color='primary'
        onClick={handleOnClick}
        onFocus={(e) => e.stopPropagation()}
        aria-label='actions menu button'
      >
        {t('common.actions')}
      </Button>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={!!anchorEl}
        onClose={handleOnClose}
      >
        {actions.map(({ disabled, label, onClick }) =>
          <MenuItem
            disabled={!onClick || disabled}
            onClick={(event) => {
              handleOnClose(event)
              onClick()
            }}
            key={label}
          >
            {t(`actionsMenuLabel.${label}`)}
          </MenuItem>
        )}
      </Menu>
    </>
  )
}

const ActionPropType = PropTypes.shape({
  onClick: PropTypes.func,
  label: PropTypes.string,
  disabled: PropTypes.bool
})

ActionsMenu.propTypes = {
  actions: PropTypes.arrayOf(ActionPropType),
  onClick: PropTypes.func
}

export default ActionsMenu
