import PropTypes from 'prop-types'
import React from 'react'
import ModalScreen from '../../components/modals/modalComponents/ModalScreen'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { useShop } from '../../components/shop/hooks'
import ModalConfirmation from './form/ModalConfirmation'

const DeleteShop = ({ onCancel = () => {}, onSubmit = () => {} }) => {
  const params = useParams()
  const shop = useShop(params.id)
  const { t } = useTranslation('oasisBackoffice')

  return (
    <ModalScreen
      openModal
      onClose={onCancel}
      title={t('actionDialogs.titleDeleteShop', { shop: shop.name })}
      withForm
    >
      <ModalConfirmation
        onSubmit={onSubmit}
        onCancel={onCancel}
        submitLabel='buttonLabelDelete'
      />
    </ModalScreen>
  )
}

DeleteShop.propTypes = {
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func
}

export default DeleteShop
