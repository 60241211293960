import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import VisibilityIcon from '@material-ui/icons/Visibility'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'
import { Field, useFormikContext } from 'formik'
import { TextField } from 'formik-material-ui'
import PropTypes from 'prop-types'
import React, { useCallback, useImperativeHandle, useState } from 'react'
import { FieldTypes } from './fields'
import { useTranslation } from 'react-i18next'
import { setPassphraseValue } from './helpers'
import { GoogleReCaptcha, useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import logger from '../../logger'
import MenuItem from '@material-ui/core/MenuItem'
import MuiPhoneInput from 'mui-phone-number'

const ShowPasswordAdornment = ({ type, onClick }) => {
  const isPassword = type === FieldTypes.PASSWORD

  return (
    <InputAdornment position='end'>
      <IconButton
        edge='end'
        onClick={() => onClick(isPassword ? FieldTypes.TEXT : FieldTypes.PASSWORD)}
      >
        {
          isPassword
            ? <VisibilityOffIcon />
            : <VisibilityIcon />
        }
      </IconButton>
    </InputAdornment>
  )
}

const OasisFormField = React.forwardRef(
  ({ autoFocus = false, name, field: { hidden, ...field } }, ref) => {
    const { setFieldError, setFieldValue, values } = useFormikContext()
    const [currentType, setCurrentType] = useState(field.type)
    const { t } = useTranslation('oasisBackoffice')
    const { executeRecaptcha } = useGoogleReCaptcha()

    const InputLabelProps = {
      ...(field.InputLabelProps || {})
    }

    const InputProps = {
      autoComplete: 'off',
      ...(field.InputProps || {})
    }
    const inputProps = {
      autoComplete: 'off',
      ...(field.inputProps || {})
    }

    if (field.type === FieldTypes.PASSWORD) {
      // we need to switch the type to 'text' if we want to show the password to the user
      InputProps.endAdornment = <ShowPasswordAdornment type={currentType} onClick={setCurrentType} />
    }

    if (field.type === FieldTypes.FILE) {
      // need to keep the label above the upload file button
      InputLabelProps.shrink = true
      // formik doesn't work with files without this
      field.value = undefined
    }

    const handleOnChange = useCallback(
      (event) => {
        if (event.target !== undefined) {
          if (event.target.files) {
            // formik would set the value to the filename, and we need a file
            setFieldValue(name, event.target.files[0])
          } else if (event.target.name === 'passphrase' || event.target.name === 'confirmPassphrase') {
            if (event.target.value.length > 24) {
              setFieldError(name, t('oasisForm.errorPassphraseTooLong'))
              return null
            }
            setFieldValue(name, setPassphraseValue(event.target.value))
          } else {
            setFieldValue(name, event.target.value)
          }
        } else {
          setFieldValue(name, event)
        }
      },
      [name, setFieldValue]
    )

    const handleReCaptchaVerify = useCallback(async () => {
      if (!executeRecaptcha) {
        logger.warn('Execute recaptcha not yet available')
        return
      }

      try {
        const token = await executeRecaptcha(field.action)
        setFieldValue(name, token)
        setFieldError(name, '')
      } catch (error) {
        logger.error(`Execute recaptcha failed with error: ${error}`)
        throw error
      }
    }, [executeRecaptcha])

    useImperativeHandle(ref, () => ({
      reset: async () => {
        await handleReCaptchaVerify()
      }
    }))

    return (
      <Grid
        item
        hidden={hidden}
      >
        {field.type === FieldTypes.RECAPTCHA
          ? <GoogleReCaptcha action={field.action} onVerify={handleReCaptchaVerify} />
          : field.type === FieldTypes.PHONE
            ? <MuiPhoneInput
                {...field}
                value={values[name]}
                onChange={handleOnChange}
                fullWidth
                autoFocus={autoFocus}
                variant='outlined'
                name={name}
                label={t(`oasisForm.${field.label}`)}
              />
            : (
              <Field
                fullWidth
                autoFocus={autoFocus}
                component={TextField}
                variant='outlined'
                helperText={field.helperText || ' '}
                onChange={handleOnChange}
                name={name}
                {...field}
                label={t(`oasisForm.${field.label}`)}
                InputProps={InputProps}
                inputProps={inputProps}
                InputLabelProps={InputLabelProps}
                type={currentType}
                SelectProps={{
                  MenuProps: {
                    disableEnforceFocus: true
                  }
                }}
              >
                {field.items?.map(item => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </Field>)}
      </Grid>
    )
  })

export const FieldPropTypes = PropTypes.shape({
  name: PropTypes.string,
  type: PropTypes.string.isRequired,
  label: PropTypes.string,
  helperText: PropTypes.string,
  hidden: PropTypes.bool,
  required: PropTypes.bool,
  InputLabelProps: PropTypes.shape({}),
  InputProps: PropTypes.shape({})
})

OasisFormField.propTypes = {
  autoFocus: PropTypes.bool,
  field: FieldPropTypes.isRequired,
  name: PropTypes.string.isRequired
}

export default OasisFormField
