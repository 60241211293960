import useAuth from '../../hooks/useAuth'
import { AvailableFeatures, UserRoles } from '../../private/const'
import PropTypes from 'prop-types'
import { isFeatureEnabled } from '../../helpers'

const AuthorizedView = ({ children, features = null }) => {
  const { user } = useAuth()

  // Admins always have access
  const isAdmin = user.userRole === UserRoles.Admin

  // User is authorized if they are admin or have the required features
  const isAuthorized = isAdmin || (features && isFeatureEnabled(user.features, features))

  return isAuthorized ? children : null
}

export default AuthorizedView

AuthorizedView.propTypes = {
  children: PropTypes.node,
  features: PropTypes.arrayOf(PropTypes.oneOf(Object.values(AvailableFeatures)))
}
