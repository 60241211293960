import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import { Form, Formik } from 'formik'
import PropTypes from 'prop-types'
import React from 'react'
import { getFormikValidateFn } from '../../../components/OasisForm/helpers'
import OasisFormField from '../../../components/OasisForm/OasisFormField'
import { useTranslation } from 'react-i18next'
import { CancelButton, SubmitButton } from './ModalButtons'
import { isEqual } from 'lodash'

const useStyles = makeStyles((theme) => ({
  actionButtons: {
    marginTop: theme.spacing(1)
  }
}))

const ModalForm = ({ 
  defaultValue, 
  fields, 
  onSubmit, 
  onCancel, 
  submitLabel = 'buttonLabelSave' 
}) => {
  const { t } = useTranslation('oasisBackoffice')
  const classes = useStyles()
  const validate = getFormikValidateFn(fields, t)

  const handleOnSubmit = async (values, { setSubmitting, setErrors }) => {
    setSubmitting(true)
    await onSubmit(values, setErrors)
    setSubmitting(false)
  }

  const formControls = Object.entries(fields).map(([name, field], index) =>
    <OasisFormField
      key={name}
      name={name}
      autoFocus={index === 0}
      field={field}
    />
  )

  return (
    <Formik
      validateOnMount
      initialValues={defaultValue}
      validate={validate}
      onSubmit={handleOnSubmit}
    >
      {({ handleSubmit, isSubmitting, isValid, values }) => {
        const isSubmitDisabled = isSubmitting || !isValid || isEqual(values, defaultValue)

        return (
          <Form
            noValidate
            onSubmit={handleSubmit}
          >
            <Grid
              container
              direction='column'
              spacing={2}
              alignItems='stretch'
            >
              {formControls}
              <Grid
                className={classes.actionButtons}
                item
                container
                justifyContent='center'
                spacing={2}
              >
                {onCancel && <CancelButton onClick={onCancel} />}
                <SubmitButton
                  isSubmitting={isSubmitting}
                  submitDisabled={isSubmitDisabled}
                  label={submitLabel}
                />
              </Grid>
            </Grid>
          </Form>
        )
      }}
    </Formik>
  )
}

ModalForm.propTypes = {
  fields: PropTypes.shape({}).isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  submitLabel: PropTypes.string
}

export default ModalForm
