const basePath = '/app'

const privateRoutes = {
  root: basePath,
  assignLicensesToOwner: `${basePath}/owner/:id/assign-licenses`,
  assignEmployeesToShop: `${basePath}/shop/:id/assign-employees`,
  assignLicensesToShop: `${basePath}/shop/:id/assign-licenses`,
  createOwner: `${basePath}/distributor/:id/create-owner`,
  activateCountingFunction: `${basePath}/distributor/:id/activate-counting`,
  activateSocialConceptFunction: `${basePath}/distributor/:id/activate-social-concept`,
  deleteShop: `${basePath}/owner/:id/delete-shop`,
  freeLicense: `${basePath}/shop/:id/free-license`,
  createShop: `${basePath}/owner/:id/create-shop`,
  editShopCredentials: `${basePath}/shop/:id/edit-shop-credentials`,
  editShop: `${basePath}/shop/:id/edit-shop`,
  createEmployee: `${basePath}/owner/:id/create-employee`,
  setEmployeePassword: `${basePath}/employee/:id/set-password`,
  sendEmployeeActivationLink: `${basePath}/employee/:id/activate-account`,
  uploadCertificate: `${basePath}/distributor/:id/upload-certificate`,
  updateCertificate: `${basePath}/distributor/:id/update-certificate`,
  generateNewLicenses: `${basePath}/distributor/:id/generate-new-licenses`,
  lockShop: `${basePath}/shop/:id/lock`,
  oasisApiErrors: `${basePath}/shop/:id/oasis-api-errors/:employeeId`,
  configureSocialConcept: `${basePath}/shop/:id/configure-social-concept`,
  configureSocialConceptTemplateDistributor: `${basePath}/distributor/:id/configure-social-concept-template`,
  configureSocialConceptTemplateOwner: `${basePath}/owner/:id/configure-social-concept-template`,
  configureSocialConceptTemplateShop: `${basePath}/shop/:id/configure-social-concept-template`
}

export default privateRoutes
