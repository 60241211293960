// Field mapping groups based on the provided mapping
export const FIELD_MAPPING_GROUPS = {
  company: [
    { key: 'companyName', label: 'fieldLabelCompanyName' },
    { key: 'companyLastName', label: 'fieldLabelCompanyLastName' },
    { key: 'companyFirstName', label: 'fieldLabelCompanyFirstName' },
    { key: 'companyStreet', label: 'fieldLabelCompanyStreet' },
    { key: 'companyCity', label: 'fieldLabelCompanyCity' },
    { key: 'companyManagingDirector', label: 'fieldLabelCompanyManagingDirector' },
    { key: 'companyTelNr', label: 'fieldLabelCompanyTelNr' },
    { key: 'companyEMailAddress', label: 'fieldLabelCompanyEMailAddress' }
  ],
  socialConceptResponsibleCompany: [
    { key: 'socialConceptResponsibleCompanyLastName', label: 'fieldLabelSCResponsibleCompanyLastName' },
    { key: 'socialConceptResponsibleCompanyFirstName', label: 'fieldLabelSCResponsibleCompanyFirstName' },
    { key: 'socialConceptResponsibleCompanyTelNr', label: 'fieldLabelSCResponsibleCompanyTelNr' },
    { key: 'socialConceptResponsibleCompanyEMail', label: 'fieldLabelSCResponsibleCompanyEMail' }
  ],
  shop: [
    { key: 'shopName', label: 'fieldLabelShopName' },
    { key: 'shopAddress', label: 'fieldLabelShopAddress' },
    { key: 'shopCity', label: 'fieldLabelShopCity' },
    { key: 'shopState', label: 'fieldLabelShopState' }
  ],
  operator: [
    { key: 'operatorLastName', label: 'fieldLabelOperatorLastName' },
    { key: 'operatorFirstName', label: 'fieldLabelOperatorFirstName' },
    { key: 'operatorEmployer', label: 'fieldLabelOperatorEmployer' },
    { key: 'operatorPositionInName', label: 'fieldLabelOperatorPositionInName' }
  ],
  playerProtectionOfficer: [
    { key: 'playerProtectionOfficerLastName', label: 'fieldLabelPlayerProtectionOfficerLastName' },
    { key: 'playerProtectionOfficerFirstName', label: 'fieldLabelPlayerProtectionOfficerFirstName' },
    { key: 'playerProtectionOfficerTelNr', label: 'fieldLabelPlayerProtectionOfficerTelNr' },
    { key: 'playerProtectionOfficerEMail', label: 'fieldLabelPlayerProtectionOfficerEMail' }
  ],
  authority: [
    { key: 'authorityName', label: 'fieldLabelAuthorityName' },
    { key: 'authorityStreet', label: 'fieldLabelAuthorityStreet' },
    { key: 'authorityCity', label: 'fieldLabelAuthorityCity' }
  ]
}

// Map entity types to field groups
export const ENTITY_FIELD_GROUPS = {
  distributor: ['company', 'socialConceptResponsibleCompany'],
  owner: ['operator', 'playerProtectionOfficer'],
  shop: ['shop', 'authority']
}

// Flat mapping of all fields
export const ALL_FIELDS = Object.values(FIELD_MAPPING_GROUPS).flat()
