import { useTranslation } from 'react-i18next'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import React from 'react'
import ButtonWithLoader from '../../../components/common/ButtonWithLoader'
import PropTypes from 'prop-types'
import { noop } from 'lodash'

export const CancelButton = ({ onClick = noop, disabled = false }) => {
  const { t } = useTranslation('oasisBackoffice')

  return (
    <Grid item>
      <Button
        variant='outlined'
        color='primary'
        onClick={onClick}
        disabled={disabled}
      >
        {t('oasisForm.buttonLabelCancel')}
      </Button>
    </Grid>
  )
}

CancelButton.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool
}

export const SubmitButton = ({
  isSubmitting = false,
  submitDisabled = false,
  label = 'buttonLabelSubmit',
  onClick = noop,
  loading = false
}) => {
  const { t } = useTranslation('oasisBackoffice')
  const isButtonDisabled = submitDisabled || loading || isSubmitting
  const isButtonLoading = loading || isSubmitting

  return (
    <Grid item>
      <ButtonWithLoader
        loading={isButtonLoading}
        variant='contained'
        color='primary'
        disabled={isButtonDisabled}
        type='submit'
        onClick={onClick}
      >
        {t(`oasisForm.${label}`)}
      </ButtonWithLoader>
    </Grid>
  )
}

SubmitButton.propTypes = {
  isSubmitting: PropTypes.bool,
  submitDisabled: PropTypes.bool,
  label: PropTypes.string,
  onClick: PropTypes.func,
  loading: PropTypes.bool
}
