import PropTypes from 'prop-types'
import React from 'react'
import ModalScreen from '../../components/modals/modalComponents/ModalScreen'
import { confirmPasswordInput, emailInput, passwordInput } from '../../components/OasisForm/fields'
import { fieldsToForm } from '../../components/OasisForm/helpers'
import ModalForm from './form/ModalForm'
import { useTranslation } from 'react-i18next'

const fields = {
  email: emailInput,
  password: passwordInput,
  confirmPassword: confirmPasswordInput
}

const emptyForm = fieldsToForm(fields)

const CreateEmployee = ({ onCancel = () => {}, onSubmit = () => {} }) => {
  const { t } = useTranslation('oasisBackoffice')

  const handleSubmit = async (values, setErrors) => {
    try {
      return await onSubmit(values)
    } catch (e) {
      setErrors({
        email: e.error.email ? t('actionDialogs.errorCreateEmployee') : undefined
      })
    }
  }

  return (
    <ModalScreen
      openModal
      onClose={onCancel}
      title={t('actionDialogs.titleCreateEmployee')}
      withForm
    >
      <ModalForm
        fields={fields}
        defaultValue={emptyForm}
        onSubmit={handleSubmit}
        onCancel={onCancel}
      />
    </ModalScreen>
  )
}

CreateEmployee.propTypes = {
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func
}

export default CreateEmployee
