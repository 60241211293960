import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import ModalScreen from '../../components/modals/modalComponents/ModalScreen'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import ModalForm from './form/ModalForm'
import { ENTITY_FIELD_GROUPS, FIELD_MAPPING_GROUPS } from '../../constants/fieldMappings'
import { fieldsToForm } from '../../components/OasisForm/helpers'
import { useDistributor } from '../../components/distributor/hooks'
import { useOwner } from '../../components/owner/hooks'
import { useShop } from '../../components/shop/hooks'
import { FieldTypes, phoneInput } from '../../components/OasisForm/fields'
import { api } from '../../services/api'
import logger from '../../logger'
import { useBackOffice } from '../hooks/useBackOffice'

const { getSocialConceptTemplateAttributes } = api

const ConfigureSocialConceptTemplate = ({
  onCancel = () => {},
  onSubmit = () => {},
  entityType
}) => {
  const params = useParams()
  const { t } = useTranslation('oasisBackoffice')
  const distributor = useDistributor()
  const owner = entityType === 'owner' ? useOwner(params.id) : null
  const shop = entityType === 'shop' ? useShop(params.id) : null
  const [{ metaData }] = useBackOffice()
  const [existingAttributes, setExistingAttributes] = useState({})
  const [isLoading, setIsLoading] = useState(true)

  // Get entity name for the title
  const entityName = useMemo(() => {
    switch (entityType) {
      case 'distributor':
        return distributor?.name || 'distributor'
      case 'owner':
        return owner?.name || 'owner'
      case 'shop':
        return shop?.name || 'shop'
      default:
        return entityType
    }
  }, [entityType, distributor, owner, shop])

  // Get state label from shop location
  const stateLabel = useMemo(() => {
    if (entityType === 'shop' && shop?.location) {
      return metaData.locations.find(loc => loc.id === shop.location)?.label || ''
    }
    return ''
  }, [entityType, shop?.location, metaData.locations])

  // Map state label back to location ID
  const mapStateLabelToId = useCallback((label) => {
    const location = metaData.locations.find(loc => loc.label === label)
    return location?.id || ''
  }, [metaData.locations])

  // Fetch existing attributes
  const fetchAttributes = useCallback(async () => {
    try {
      const attributes = await getSocialConceptTemplateAttributes(entityType, params.id)
      const attributesMap = attributes.reduce((acc, attr) => {
        acc[attr.fieldName] = attr.value
        return acc
      }, {})
      setExistingAttributes(attributesMap)
    } catch (error) {
      logger.error(`Failed to fetch template attributes for ${entityType} ${params.id}: ${error.message}`)
    } finally {
      setIsLoading(false)
    }
  }, [entityType, params.id])

  useEffect(() => {
    fetchAttributes()
  }, [fetchAttributes])

  // Create fields from the appropriate field groups
  const fieldGroups = ENTITY_FIELD_GROUPS[entityType] || []

  // Build fields object with all applicable fields
  const fields = {}
  fieldGroups.forEach(group => {
    if (FIELD_MAPPING_GROUPS[group]) {
      FIELD_MAPPING_GROUPS[group].forEach(field => {
        // Choose field type based on key name
        let fieldType = FieldTypes.TEXT
        const additionalProps = {}

        if (field.key.toLowerCase().includes('email')) {
          fieldType = FieldTypes.EMAIL
        } else if (field.key.toLowerCase().includes('telnr') || field.key.toLowerCase().includes('phone')) {
          fieldType = FieldTypes.PHONE
          Object.assign(additionalProps, phoneInput)
        }

        fields[field.key] = {
          ...additionalProps,
          type: fieldType,
          label: field.label,
          required: false,
          disabled: field.key === 'shopState' // Keep state field disabled
        }
      })
    }
  })

  // Create default values using existing attributes and shop data
  const defaultValues = Object.keys(fields).reduce((acc, key) => {
    // If we have an existing attribute value, use it
    if (existingAttributes[key]) {
      acc[key] = existingAttributes[key]
      return acc
    }

    // Otherwise, use shop data or state label
    if (key === 'shopState') {
      acc[key] = stateLabel
    } else if (entityType === 'shop' && shop) {
      switch (key) {
        case 'shopName':
          acc[key] = shop.name || ''
          break
        case 'shopAddress':
          acc[key] = shop.address || ''
          break
        case 'shopCity':
          acc[key] = shop.city || ''
          break
        case 'shopTelNr':
          acc[key] = shop.phoneNumber || ''
          break
        default:
          acc[key] = ''
      }
    } else {
      acc[key] = ''
    }
    return acc
  }, {})

  // Prepare form with defaults
  const formWithDefaults = fieldsToForm(fields, defaultValues)

  const handleSubmit = (values) => {
    // Transform all form values to API format, filtering out null values
    const attributes = Object.entries(values)
      .filter(([_, value]) => value !== null && value !== undefined && value !== '')
      .map(([fieldName, value]) => {
        let finalValue = value
        if (fieldName === 'shopState') {
          const stateId = mapStateLabelToId(value)
          finalValue = stateId ? String(stateId) : ''
        } else {
          finalValue = value.toString()
        }
        return {
          fieldName,
          value: finalValue
        }
      })

    onSubmit(attributes)
  }

  if (isLoading) {
    return null // Or a loading spinner
  }

  return (
    <ModalScreen
      openModal
      onClose={onCancel}
      title={t('actionDialogs.titleConfigureSKTemplate', { name: entityName })}
      withForm
    >
      <ModalForm
        fields={fields}
        defaultValue={formWithDefaults}
        onCancel={onCancel}
        onSubmit={handleSubmit}
        submitLabel='buttonLabelSave'
      />
    </ModalScreen>
  )
}

ConfigureSocialConceptTemplate.propTypes = {
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  entityType: PropTypes.oneOf(['distributor', 'owner', 'shop']).isRequired
}

export default ConfigureSocialConceptTemplate
