import PropTypes from 'prop-types'
import React from 'react'
import ModalScreen from '../../components/modals/modalComponents/ModalScreen'
import {
  emailInput,
  nameInput,
  phoneInput,
  shopLocationSelect,
  shopScheduleSelect,
  shopTypeSelect
} from '../../components/OasisForm/fields'
import ModalForm from './form/ModalForm'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { useShop } from '../../components/shop/hooks'
import {
  fieldsToForm,
  getChangedValues
} from '../../components/OasisForm/helpers'
import { useBackOffice } from '../hooks/useBackOffice'
import useAuth from '../../hooks/useAuth'
import { getScheduleDropdownItems } from './helpers'

const EditShop = ({ onCancel = () => {}, onSubmit = () => {} }) => {
  const params = useParams()
  const shop = useShop(params.id)
  const { t } = useTranslation('oasisBackoffice')
  const [state] = useBackOffice()

  const {
    user: { userRole }
  } = useAuth()

  const defaultValue = {
    name: shop.name,
    type: shop.type,
    email: shop.email,
    phoneNumber: shop.phoneNumber,
    location: shop.location,
    schedule: shop.schedule
  }

  const fields = {
    name: nameInput,
    type: {
      ...shopTypeSelect,
      items: state.metaData.types.map((type) => ({
        value: type.id,
        label: type.label
      }))
    },
    email: emailInput,
    phoneNumber: phoneInput,
    location: {
      ...shopLocationSelect,
      items: state.metaData.locations.map((location) => ({
        value: location.id,
        label: location.label
      }))
    },
    schedule: {
      ...shopScheduleSelect,
      disabled: userRole !== 3,
      items: getScheduleDropdownItems(state.schedules)
    }
  }

  const formWithDefaults = fieldsToForm(fields, defaultValue)

  const handleOnSubmit = async (values, setErrors) => {
    const valuesToSend = getChangedValues(values, defaultValue)
    onSubmit(valuesToSend, setErrors)
  }

  return (
    <ModalScreen
      openModal
      onClose={onCancel}
      title={t('actionDialogs.titleEditShop', { shop: shop.name })}
      withForm
      disableEnforceFocus
    >
      <ModalForm
        fields={fields}
        defaultValue={formWithDefaults}
        onSubmit={handleOnSubmit}
        onCancel={onCancel}
        submitLabel='buttonLabelEdit'
      />
    </ModalScreen>
  )
}

EditShop.propTypes = {
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func
}

export default EditShop
