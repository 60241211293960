import PropTypes from 'prop-types'
import React from 'react'
import ModalScreen from '../../components/modals/modalComponents/ModalScreen'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { useShop } from '../../components/shop/hooks'
import ModalConfirmation from './form/ModalConfirmation'

const LockShop = ({ onCancel = () => {}, onSubmit = () => {} }) => {
  const params = useParams()
  const shop = useShop(params.id)
  const { t } = useTranslation('oasisBackoffice')

  const modalTitle = shop.locked
    ? t('actionDialogs.titleUnlockShop', { shop: shop.name })
    : t('actionDialogs.titleLockShop', { shop: shop.name })

  const buttonLabel = shop.locked ? 'buttonLabelUnlock' : 'buttonLabelLock'

  const handleSubmit = () => {
    onSubmit({
      locked: !shop.locked
    })
  }

  return (
    <ModalScreen
      openModal
      onClose={onCancel}
      title={modalTitle}
      withForm
    >
      <ModalConfirmation
        onSubmit={handleSubmit}
        onCancel={onCancel}
        submitLabel={buttonLabel}
      />
    </ModalScreen>
  )
}

LockShop.propTypes = {
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func
}

export default LockShop
