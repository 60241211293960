import React from 'react'
import { Route, Routes } from 'react-router-dom'
import ModalRoute from '../../private/ModalRoute'
import ActivateCountingFunction from '../../private/actionDialogs/ActivateCountingFunction'
import ActivateSocialConceptFunction from '../../private/actionDialogs/ActivateSocialConceptFunction'
import ConfigureSocialConceptTemplate from '../../private/actionDialogs/ConfigureSocialConceptTemplate'
import GenerateNewLicenses from '../../private/actionDialogs/GenerateNewLicenses'
import UploadDistributorCertificate from '../../private/actionDialogs/UploadDistributorCertificate'
import { AvailableFeatures } from '../../private/const'
import AuthorizedView from '../common/AuthorizedView'

const DistributorRoutes = ({
  handleGenerateNewLicenses,
  handleUpdateCertificate,
  handleUpdateCountingFunction,
  handleConfigureSocialConceptTemplate
}) =>
  (
    <Routes>
      <Route
        path='*'
        element={
          <>
            <ModalRoute
              path='generate-new-licenses'
              component={GenerateNewLicenses}
              onSubmit={handleGenerateNewLicenses}
            />
            <ModalRoute
              path='update-certificate'
              component={UploadDistributorCertificate}
              onSubmit={handleUpdateCertificate}
            />
            <AuthorizedView features={[AvailableFeatures.oasisApiCounting]}>
              <ModalRoute
                path='activate-counting'
                component={ActivateCountingFunction}
                onSubmit={handleUpdateCountingFunction}
              />
            </AuthorizedView>
            <AuthorizedView features={[AvailableFeatures.digitalSocialConcept]}>
              <ModalRoute
                path='activate-social-concept'
                component={ActivateSocialConceptFunction}
                onSubmit={handleUpdateCountingFunction}
              />
            </AuthorizedView>
            <AuthorizedView features={[AvailableFeatures.digitalSocialConcept]}>
              <ModalRoute
                path='configure-social-concept-template'
                onSubmit={(id, data) => handleConfigureSocialConceptTemplate('distributor', id, data)}
                component={(props) => <ConfigureSocialConceptTemplate {...props} entityType='distributor' />}
              />
            </AuthorizedView>
          </>
        }
      />
    </Routes>
  )

export default DistributorRoutes
