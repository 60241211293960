import { sortBy } from 'lodash'
import { useMemo } from 'react'
import useNavigateTo from '../../hooks/useNavigateTo'
import { useBackOffice } from '../../private/hooks/useBackOffice'
import privateRoutes from '../../private/routes'
import { useAssignLicenses, useShopAssignableLicenses } from '../license/hooks'
import useAuth from '../../hooks/useAuth'
import { UserFeatures, UserRoles } from '../../private/const'
import { isFeatureActivated } from '../../private/featureHelpers'
import { useDistributor } from '../distributor/hooks'

export const useShopMenuActions = (shop) => {
  const navigateTo = useNavigateTo()
  const licenses = useShopAssignableLicenses(shop.id)
  const assignLicenses = useAssignLicenses(licenses, () => navigateTo(privateRoutes.assignLicensesToShop, { id: shop.id }))
  const { user: { userRole } } = useAuth()
  const distributor = useDistributor()
  const distributorUserFeatures = distributor?.users?.[0]?.features || 0

  const isAdmin = userRole === UserRoles.Admin
  const hasSKFeature = isFeatureActivated(distributorUserFeatures, UserFeatures.DigitalSocialConcept)

  return useMemo(
    () => {
      const distributorActions = [
        {
          label: 'assignEmployees',
          onClick: () => navigateTo(privateRoutes.assignEmployeesToShop, { id: shop.id })
        },
        assignLicenses,
        {
          label: 'deleteShop',
          onClick: () => navigateTo(privateRoutes.deleteShop, { id: shop.id })
        },
        {
          label: 'editShop',
          onClick: () => navigateTo(privateRoutes.editShop, { id: shop.id })
        },
        {
          label: 'editShopCredentials',
          onClick: () => navigateTo(privateRoutes.editShopCredentials, { id: shop.id })
        },
        {
          label: 'freeShopLicenses',
          onClick: () => navigateTo(privateRoutes.freeLicense, { id: shop.id })
        }
      ]

      const adminAction = {
        label: shop.locked ? 'unlockShop' : 'lockShop',
        onClick: () => navigateTo(privateRoutes.lockShop, { id: shop.id })
      }

      if (hasSKFeature || isAdmin) {
        distributorActions.push({
          label: shop.socialConceptEnabled ? 'deactivateSK' : 'activateSK',
          onClick: () => navigateTo(privateRoutes.configureSocialConcept, { id: shop.id })
        })
      }

      if (isAdmin && hasSKFeature) {
        distributorActions.push({
          label: 'configureSKTemplate',
          onClick: () => navigateTo(privateRoutes.configureSocialConceptTemplateShop, { id: shop.id })
        })
      }

      isAdmin && distributorActions.push(adminAction)

      return distributorActions
    },
    [shop.id, shop.locked, shop.socialConceptEnabled, licenses, navigateTo, isAdmin, hasSKFeature]
  )
}

export const useShops = (ownerId) => {
  const [{ shops: { ids = [], byId } }] = useBackOffice()

  return useMemo(
    () => ids.filter((id) => byId[id].owner === ownerId),
    [ownerId, ids, byId]
  )
}

export const useSortedShops = (ownerId) => {
  const shopIds = useShops(ownerId)
  const [{ shops: { byId } }] = useBackOffice()

  return useMemo(
    () => sortBy(shopIds, (id) => byId[id].name.toLocaleLowerCase()),
    [ownerId, shopIds, byId]
  )
}

export const useShop = (id) => {
  const [{ shops: { byId } }] = useBackOffice()

  return byId[id]
}

export const useCreateShop = (id) => {
  const navigateTo = useNavigateTo()
  return () => navigateTo(privateRoutes.createShop, { id })
}

export const useCreateOwner = (id) => {
  const navigateTo = useNavigateTo()
  return () => navigateTo(privateRoutes.createOwner, { id })
}
