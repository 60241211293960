import { Accordion, AccordionDetails } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import React from 'react'
import AccordionSummaryWithActions from '../common/AccordionSummaryWithActions'
import EmployeeTable from '../employee/EmployeeTable'
import { useShopEmployees } from '../employee/hooks'
import { useShopLicenses } from '../license/hooks'
import LicenseTable from '../license/LicenseTable'
import { useShop, useShopMenuActions } from './hooks'
import { Description, Lock, LockOpen, Storefront } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'
import { useBackOffice } from '../../private/hooks/useBackOffice'
import { api } from '../../services/api'
import logger from '../../logger'

const useStyles = makeStyles((theme) => ({
  root: {
    paddingInline: theme.spacing(3),
    flexDirection: 'column'
  },
  socialConceptSection: {
    marginTop: theme.spacing(3)
  },
  socialConceptButton: {
    marginLeft: theme.spacing(8),
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    padding: theme.spacing(1),
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.action.hover
    }
  }
}))

const Shop = ({ onClick, expanded, id }) => {
  const classes = useStyles()
  const shop = useShop(id)
  const licenses = useShopLicenses(id)
  const employees = useShopEmployees(id)
  const handleOnClick = () => onClick(id)
  const { t } = useTranslation('oasisBackoffice')
  const [{ scans, schedules, oasisApiCalls }] = useBackOffice()
  const schedule = schedules.byId[shop.schedule]

  const menuActions = useShopMenuActions(shop)

  const shopScanCount = scans.byShopId[id]?.scans.length
  const shopOasisApiCalls = oasisApiCalls.byShopId[id]?.oasisApiCalls

  const handleOpenSocialConcept = async () => {
    try {
      const pdfBuffer = await api.getSocialConcept(shop.socialConceptId)
      const blob = new Blob([pdfBuffer], { type: 'application/pdf' })
      const url = window.URL.createObjectURL(blob)
      window.open(url, '_blank')
    } catch (error) {
      logger.error('Failed to open social concept', { error, shopId: id, socialConceptId: shop.socialConceptId })
    }
  }

  return (
    <Accordion expanded={expanded} onChange={handleOnClick}>
      <AccordionSummaryWithActions
        header={shop.name}
        actions={menuActions}
        scanCount={shopScanCount}
        shopSchedule={schedule?.name}
        oasisApiCalls={shopOasisApiCalls}
      >
        <Storefront fontSize='medium' color='disabled' />
        {shop.locked
          ? <Lock fontSize='medium' color='action' />
          : <LockOpen fontSize='medium' color='disabled' />}
      </AccordionSummaryWithActions>
      <AccordionDetails classes={{ root: classes.root }}>
        {shop.locked && (
          <Typography variant='h5' align='center'>
            {t('shop.shopLocked')}
          </Typography>
        )}
        <Typography variant='h6'>{t('shop.headerEmployee')}</Typography>
        <EmployeeTable
          items={employees}
          employeeScans={scans.byShopId[id]?.scansByEmployeeId}
          employeeOasisApiCalls={oasisApiCalls.byShopId[id]?.oasisApiCallsByEmployeeId}
          shopId={id}
        />
        <Typography variant='h6'>{t('shop.headerLicenses')}</Typography>
        <LicenseTable items={licenses} />
        {shop.socialConceptEnabled && (
          <div className={classes.socialConceptSection}>
            <Typography variant='h6'>{t('shop.headerSocialConcept')}</Typography>
            <div className={classes.socialConceptButton} onClick={handleOpenSocialConcept}>
              <Description fontSize='medium' color='action' />
              <Typography>{t('shop.viewSocialConcept')}</Typography>
            </div>
          </div>
        )}
      </AccordionDetails>
    </Accordion>
  )
}

export default Shop
