import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import React from 'react'
import { useBackOffice } from '../../private/hooks/useBackOffice'
import EmployeeHeader from './EmployeeHeader'
import EmployeeRow from './EmployeeRow'

const EmployeeTable = ({ items = [], employeeScans, employeeOasisApiCalls, shopId }) => {
  const [state] = useBackOffice()

  return (
    <TableContainer style={{ paddingBlock: '16px 32px' }}>
      <Table>
        <TableHead>
          <EmployeeHeader />
        </TableHead>
        <TableBody>
          {items.map((id) => (
            <EmployeeRow
              item={state.employees.byId[id]}
              key={id}
              scanCount={employeeScans?.[id]?.length}
              oasisApiCalls={employeeOasisApiCalls?.[id]}
              shopId={shopId}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default EmployeeTable
