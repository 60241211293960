import PropTypes from 'prop-types'
import React from 'react'
import ModalScreen from '../../components/modals/modalComponents/ModalScreen'
import { contactInfoTextArea, emailInput, nameInput } from '../../components/OasisForm/fields'
import { fieldsToForm } from '../../components/OasisForm/helpers'
import ModalForm from './form/ModalForm'
import { useTranslation } from 'react-i18next'

const fields = {
  name: nameInput,
  email: emailInput,
  contactInfo: contactInfoTextArea
}

const emptyForm = fieldsToForm(fields)

const CreateOwner = ({ onCancel = () => {}, onSubmit = () => {} }) => {
  const { t } = useTranslation('oasisBackoffice')

  return (
    <ModalScreen
      openModal
      onClose={onCancel}
      title={t('actionDialogs.titleCreatOwner')}
      withForm
    >
      <ModalForm
        fields={fields}
        defaultValue={emptyForm}
        onSubmit={onSubmit}
        onCancel={onCancel}
        submitLabel='buttonLabelCreate'
      />
    </ModalScreen>
  )
}

CreateOwner.propTypes = {
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func
}

export default CreateOwner
