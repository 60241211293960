import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import { Link } from 'react-router-dom'
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import ButtonWithLoader from '../common/ButtonWithLoader'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(() => ({
  wrapper: {
    minWidth: '100%'
  }
}))

const OasisFormButtons = ({ 
  backHref = '', 
  submitDisabled = false, 
  submitLabel = '', 
  isSubmitting, 
  ...props 
}) => {
  const classes = useStyles()
  const { t } = useTranslation('oasisBackoffice')

  return (
    <Box
      className={classes.wrapper}
      display='flex'
      justifyContent='center'
      {...props}
    >
      <Grid
        container
        justifyContent='center'
        spacing={4}
      >
        {!!backHref && (
          <Grid item>
            <Button
              component={Link}
              variant='outlined'
              color='primary'
              to={backHref}
            >
              {t('oasisForm.buttonLabelBack')}
            </Button>
          </Grid>
        )}
        <Grid item>
          <ButtonWithLoader
            type='submit'
            variant='contained'
            color='primary'
            loading={isSubmitting}
            disabled={submitDisabled}
          >
            {t(`oasisForm.${submitLabel}`)}
          </ButtonWithLoader>
        </Grid>
      </Grid>
    </Box>
  )
}

OasisFormButtons.propTypes = {
  backHref: PropTypes.string,
  submitDisabled: PropTypes.bool,
  submitLabel: PropTypes.string
}

export default OasisFormButtons
