import PropTypes from 'prop-types'
import React from 'react'
import ModalScreen from '../../components/modals/modalComponents/ModalScreen'
import { useTranslation } from 'react-i18next'
import { useDistributor } from '../../components/distributor/hooks'
import ModalConfirmation from './form/ModalConfirmation'
import { makeStyles } from '@material-ui/core/styles'
import { UserFeatures } from '../const'
import { isFeatureActivated, setFeature } from '../featureHelpers'

const useStyles = makeStyles(() => ({
  content: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '20px'
  }
}))

const ActivateSocialConceptFunction = ({ onSubmit = () => {}, onCancel = () => {} }) => {
  const classes = useStyles()
  const distributor = useDistributor()
  const distributorUser = distributor.users[0]
  const { t } = useTranslation('oasisBackoffice')

  const content = isFeatureActivated(distributorUser.features, UserFeatures.DigitalSocialConcept)
    ? {
      buttonLabel: 'buttonLabelDeactivate',
      text: t('actionDialogs.deactivate')
    }
    : {
      buttonLabel: 'buttonLabelActivate',
      text: t('actionDialogs.activate')
    }

  const handleSubmit = async () => {
    await onSubmit({
      userId: distributorUser.id,
      features: setFeature(distributorUser.features, UserFeatures.DigitalSocialConcept, !isFeatureActivated(distributorUser.features, UserFeatures.DigitalSocialConcept))
    })
  }

  return (
    <ModalScreen
      openModal
      onClose={onCancel}
      title={t('actionDialogs.skFunctionActivation')}
      withForm
    >
      <div className={classes.content}>
        {t('actionDialogs.skFunctionActivationText', { modalText: content.text, name: distributor.name })}
      </div>
      <ModalConfirmation
        onSubmit={handleSubmit}
        onCancel={onCancel}
        submitLabel={content.buttonLabel}
      />
    </ModalScreen>
  )
}

ActivateSocialConceptFunction.propTypes = {
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func
}

export default ActivateSocialConceptFunction
