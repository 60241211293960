import PropTypes from 'prop-types'
import React from 'react'
import ModalScreen from '../../components/modals/modalComponents/ModalScreen'
import { nameInput, passwordInput } from '../../components/OasisForm/fields'
import { fieldsToForm } from '../../components/OasisForm/helpers'
import ModalForm from './form/ModalForm'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { useShop } from '../../components/shop/hooks'
import { noop } from 'lodash'

const fields = {
  certificateUsername: { ...nameInput, label: 'fieldLabelNewOasisUserName' },
  certificatePassword: { ...passwordInput, label: 'fieldLabelNewOasisPassword' }
}

const emptyForm = fieldsToForm(fields)

const EditShopCredentials = ({ onCancel = noop, onSubmit = noop }) => {
  const params = useParams()
  const shop = useShop(params.id)
  const { t } = useTranslation('oasisBackoffice')

  return (
    <ModalScreen
      openModal
      onClose={onCancel}
      title={t('actionDialogs.titleEditShopCredentials', { shop: shop.name })}
      withForm
    >
      <ModalForm
        fields={fields}
        defaultValue={emptyForm}
        onSubmit={onSubmit}
        onCancel={onCancel}
        submitLabel='buttonLabelEdit'
      />
    </ModalScreen>
  )
}

EditShopCredentials.propTypes = {
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func
}

export default EditShopCredentials
