import { Box } from '@material-ui/core'
import PropTypes from 'prop-types'
import React from 'react'
import { useParams } from 'react-router'
import { useEmployee } from '../../components/employee/hooks'
import ModalScreen from '../../components/modals/modalComponents/ModalScreen'
import { confirmPasswordInput, emailInput, passwordInput } from '../../components/OasisForm/fields'
import { fieldsToForm } from '../../components/OasisForm/helpers'
import ModalForm from './form/ModalForm'
import { useTranslation } from 'react-i18next'

const fields = {
  email: { ...emailInput, hidden: true },
  password: passwordInput,
  confirmPassword: confirmPasswordInput
}

const emptyForm = fieldsToForm(fields)

const SetEmployeePassword = ({ onCancel = () => {}, onSubmit = () => {} }) => {
  const { id } = useParams()
  const employee = useEmployee(id)
  const { t } = useTranslation('oasisBackoffice')

  const title = <Box>{t('actionDialogs.titleSetEmployeePassword')}<Box component='span' fontWeight='fontWeightBold'>{employee.email}</Box></Box>

  const handleOnSubmit = ({ password }) => onSubmit({ password })

  return (
    <ModalScreen
      openModal
      onClose={onCancel}
      title={title}
      withForm
    >
      <ModalForm
        fields={fields}
        defaultValue={emptyForm}
        onSubmit={handleOnSubmit}
        onCancel={onCancel}
      />
    </ModalScreen>
  )
}

SetEmployeePassword.propTypes = {
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func
}

export default SetEmployeePassword
