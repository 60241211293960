import { sortBy } from 'lodash'
import { useMemo } from 'react'
import useNavigateTo from '../../hooks/useNavigateTo'
import { useBackOffice } from '../../private/hooks/useBackOffice'
import privateRoutes from '../../private/routes'
import { useAssignLicenses, useOwnerAssignableLicenses } from '../license/hooks'
import { useCreateShop } from '../shop/hooks'
import useAuth from '../../hooks/useAuth'
import { UserFeatures, UserRoles } from '../../private/const'
import { isFeatureActivated } from '../../private/featureHelpers'
import { useDistributor } from '../distributor/hooks'

export const useOwnerMenuActions = (id) => {
  const navigateTo = useNavigateTo()
  const createShop = useCreateShop(id)
  const licenses = useOwnerAssignableLicenses(id)
  const assignLicensesAction = useAssignLicenses(licenses, () => navigateTo(privateRoutes.assignLicensesToOwner, { id }))
  const { user: { userRole } } = useAuth()
  const distributor = useDistributor()
  const distributorUserFeatures = distributor?.users?.[0]?.features || 0

  const isAdmin = userRole === UserRoles.Admin
  const hasSKFeature = isFeatureActivated(distributorUserFeatures, UserFeatures.DigitalSocialConcept)

  return useMemo(
    () => {
      const actions = [
        {
          label: 'addEmployee',
          onClick: () => navigateTo(privateRoutes.createEmployee, { id })
        },
        assignLicensesAction,
        {
          label: 'createShop',
          onClick: createShop
        }
      ]

      if (isAdmin && hasSKFeature) {
        actions.push({
          label: 'configureSKTemplate',
          onClick: () => navigateTo(privateRoutes.configureSocialConceptTemplateOwner, { id })
        })
      }

      return actions
    },
    [id, licenses, navigateTo, createShop, isAdmin, hasSKFeature]
  )
}

export const useOwners = (distributorId) => {
  const [{ owners: { ids = [], byId } }] = useBackOffice()

  return useMemo(
    () => ids.filter((id) => byId[id].distributor === distributorId),
    [distributorId, ids, byId]
  )
}

export const useSortedOwners = (distributorId) => {
  const ownerIds = useOwners(distributorId)
  const [{ owners: { byId } }] = useBackOffice()

  return useMemo(
    () => sortBy(ownerIds, (id) => byId[id].name.toLocaleLowerCase()),
    [distributorId, ownerIds, byId]
  )
}

export const useFilteredOwners = (distributorId, search) => {
  const sortedOwners = useSortedOwners(distributorId)
  const [{ owners: { byId } }] = useBackOffice()

  return useMemo(
    () => sortedOwners.filter((id) => byId[id].name.toLocaleLowerCase().includes(search.toLocaleLowerCase())),
    [sortedOwners, byId, search]
  )
}

export const useOwner = (id) => {
  const [{ owners }] = useBackOffice()

  return owners.byId[id]
}
