import PropTypes from 'prop-types'
import React from 'react'
import ModalScreen from '../../components/modals/modalComponents/ModalScreen'
import { useTranslation } from 'react-i18next'
import { useDistributor } from '../../components/distributor/hooks'
import ModalConfirmation from './form/ModalConfirmation'
import { makeStyles } from '@material-ui/core/styles'
import { UserFeatures } from '../const'
import { isFeatureActivated, setFeature } from '../featureHelpers'

const useStyles = makeStyles(() => ({
  content: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '20px'
  }
}))

const ActivateCountingFunction = ({ onSubmit = () => {}, onCancel = () => {} }) => {
  const classes = useStyles()
  const distributor = useDistributor()
  const distributorUser = distributor.users[0]
  const { t } = useTranslation('oasisBackoffice')
  const content = isFeatureActivated(distributorUser.features, UserFeatures.OasisApiCounting)
    ? {
        buttonLabel: 'buttonLabelDeactivate',
        text: t('actionDialogs.deactivate')
      }
    : {
        buttonLabel: 'buttonLabelActivate',
        text: t('actionDialogs.activate')
      }

  const handleSubmit = async () => {
    await onSubmit({
      userId: distributorUser.id,
      features: setFeature(distributorUser.features, UserFeatures.OasisApiCounting, !isFeatureActivated(distributorUser.features, UserFeatures.OasisApiCounting))
    })
  }

  return (
    <ModalScreen
      openModal
      onClose={onCancel}
      title={t('actionDialogs.countingFunctionActivation')}
      withForm
    >
      <div className={classes.content}>
        {t('actionDialogs.countingFunctionActivationText', { modalText: content.text, name: distributor.name })}
      </div>
      <ModalConfirmation
        onSubmit={handleSubmit}
        onCancel={onCancel}
        submitLabel={content.buttonLabel}
      />
    </ModalScreen>
  )
}

ActivateCountingFunction.propTypes = {
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func
}

export default ActivateCountingFunction
