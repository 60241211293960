import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, Route, Routes } from 'react-router-dom'
import logger from '../../logger'
import { useBackOffice } from '../../private/hooks/useBackOffice'
import Layout from '../../private/Layout'
import privateRoutes from '../../private/routes'
import {
  ASSIGN_EMPLOYEES_COMPLETE,
  ASSIGN_LICENSE_COMPLETE,
  CERTIFICATE_UPDATE_COMPLETE,
  CERTIFICATE_UPLOAD_COMPLETE,
  CONFIGURE_SOCIAL_CONCEPT_COMPLETE,
  CREATE_EMPLOYEE_COMPLETE,
  CREATE_OWNER_COMPLETE,
  CREATE_SHOP_COMPLETE,
  DELETE_SHOP_COMPLETE,
  DICTIONARY_METADATA_READY,
  DISTRIBUTOR_DATA_READY,
  EDIT_SHOP_COMPLETE,
  FREE_SHOP_LICENSES,
  GENERATE_NEW_LICENSES,
  LOCK_SHOP_COMPLETE,
  UPDATE_DISTRIBUTOR_FEATURES
} from '../../reducer/actions'
import { api } from '../../services/api'
import { ApiError } from '../../services/api/const'
import Loading from '../Loading'
import DistributorContent from './DistributorContent'
import { useDistributor } from './hooks'
import { useSnackbar } from 'notistack'
import Header from '../Header/Header'
import DistributorRoutes from './DistributorRoutes'
import OwnerRoutes from './OwnerRoutes'
import ShopRoutes from './ShopRoutes'
import EmployeeRoutes from './EmployeeRoutes'
import ModalRoute from '../../private/ModalRoute'
import UploadDistributorCertificate from '../../private/actionDialogs/UploadDistributorCertificate'
import CreateOwner from '../../private/actionDialogs/CreateOwner'
import useNavigateTo from '../../hooks/useNavigateTo'
import useAuth from '../../hooks/useAuth'
import { AvailableFeatures, UserFeatures, UserRoles } from '../../private/const'
import { startOfMonth } from 'date-fns'
import { isFeatureActivated } from '../../private/featureHelpers'
import { isFeatureEnabled } from '../../helpers'

const {
  assignEmployeesToShop,
  assignLicensesToOwner,
  assignLicensesToShop,
  createEmployee,
  createOwner,
  createShop,
  editShopCredentials,
  deleteShop,
  editShop,
  fetchDistributorEmployees,
  fetchDistributorLicenses,
  fetchDistributorOwners,
  fetchDistributorShops,
  fetchDistributorScans,
  setEmployeePassword,
  sendEmployeeActivationLink,
  uploadCertificate,
  updateCertificate,
  updateUserFeature,
  freeShopLicenses,
  generateNewLicenses,
  lockShop,
  fetchDictionaryMetaData,
  fetchDistributorSchedules,
  fetchDistributorOasisApiCalls,
  configureSocialConcept,
  setSocialConceptTemplateAttributes
} = api

const Distributor = ({ id }) => {
  const [, dispatch] = useBackOffice()
  const distributor = useDistributor()
  const { t } = useTranslation('oasisBackoffice')
  const { enqueueSnackbar } = useSnackbar()
  const navigateTo = useNavigateTo()
  const {
    user
  } = useAuth()

  const [ready, setReady] = useState(false)

  const handleApiError = useCallback((error, errorKey) => {
    if (error.status === 404) {
      enqueueSnackbar(
        `${t(`errors.${errorKey}`)} ${t('errors.endpointNotFound')}`,
        { variant: 'error' }
      )
      return true
    }
    const errorMessage = error.error?.message || error.message || 'Unknown'
    enqueueSnackbar(
      `${t(`errors.${errorKey}`)} ${errorMessage}`,
      { variant: 'error' }
    )
    return false
  }, [t, enqueueSnackbar])

  useLayoutEffect(() => {
    setReady(false)
  }, [id])

  const isAdmin = user.userRole === UserRoles.Admin

  useEffect(() => {
    const fetchData = async () => {
      const commonPromises = [
        fetchDistributorShops(id),
        fetchDistributorOwners(id),
        fetchDistributorLicenses(id),
        fetchDistributorEmployees(id),
        fetchDictionaryMetaData(),
        fetchDistributorSchedules(id)
      ]

      if (isAdmin || isFeatureEnabled(user.features, [AvailableFeatures.oasisApiCounting])) {
        const date = new Date()
        const params = {
          startDate: startOfMonth(date).toISOString()
        }

        commonPromises.push(
          fetchDistributorOasisApiCalls(id, params)
        )

        if (isAdmin) {
          commonPromises.push(
            fetchDistributorScans(id, params)
          )
        }
      }

      try {
        logger.updateContext({ distributorId: id })
        logger.info('Loading distributor data')

        const [
          shops,
          owners,
          licenses,
          employees,
          metaData = [],
          schedules = [],
          oasisApiCalls = [],
          scans = []
        ] = await Promise.all(commonPromises)

        dispatch({
          type: DISTRIBUTOR_DATA_READY,
          payload: { shops, owners, licenses, employees, scans, schedules, oasisApiCalls }
        })

        dispatch({
          type: DICTIONARY_METADATA_READY,
          payload: metaData
        })

        logger.debug(`shops fetched: ${shops.length}`)
        logger.debug(`owners fetched: ${owners.length}`)
        logger.debug(`licenses fetched: ${licenses.length}`)
        logger.debug(`employees fetched: ${employees.length}`)
        logger.debug(`metadata fetched: ${metaData}`)
        logger.debug(`scans fetched: ${scans.length}`)
        logger.debug(`schedules fetched: ${schedules.length}`)
        logger.debug(`Oasis Api calls fetched: ${schedules.length}`)

        setReady(true)
      } catch (error) {
        logger.error(error.message)
        throw error
      }
    }
    fetchData()
  }, [id, dispatch])

  const hasCertificate = !!distributor?.certificate ?? false

  useEffect(() => {
    !hasCertificate &&
    navigateTo(generatePath(privateRoutes.uploadCertificate, { id }), {
      replace: true
    })
  }, [hasCertificate])

  const handleAssignLicensesToOwner = useCallback(
    async (ownerId, data) => {
      logger.info(`Updating owner license assignment for owner '${ownerId}'`, {
        ownerId
      })
      logger.debug(data)
      try {
        dispatch({
          type: ASSIGN_LICENSE_COMPLETE,
          payload: await assignLicensesToOwner(ownerId, data)
        })
        enqueueSnackbar(t('successInfo.assignLicenses'), { variant: 'success' })
      } catch (error) {
        handleApiError(error, 'assignLicensesError')
      }
    },
    [dispatch, handleApiError]
  )

  const handleAssignLicensesToShop = useCallback(
    async (shopId, data) => {
      logger.info(`Updating shop license assignment for shop '${shopId}'`, {
        shopId
      })
      logger.debug(data)
      try {
        dispatch({
          type: ASSIGN_LICENSE_COMPLETE,
          payload: await assignLicensesToShop(shopId, data)
        })
        enqueueSnackbar(t('successInfo.assignLicenses'), { variant: 'success' })
      } catch (error) {
        handleApiError(error, 'assignLicensesError')
      }
    },
    [dispatch, handleApiError]
  )

  const handleAssignEmployeesToShop = useCallback(
    async (shopId, data) => {
      logger.info(`Updating shop employee assignment for shop '${shopId}'`, {
        shopId
      })
      logger.debug(data)
      try {
        dispatch({
          type: ASSIGN_EMPLOYEES_COMPLETE,
          payload: await assignEmployeesToShop(shopId, data)
        })
        enqueueSnackbar(t('successInfo.assignEmployees'), { variant: 'success' })
      } catch (error) {
        handleApiError(error, 'assignEmployeesError')
      }
    },
    [dispatch, handleApiError]
  )

  const handleCreateOwner = useCallback(
    async (distributorId, data) => {
      logger.info(
        `Creating owner '${data.name}' for distributor '${distributorId}'`,
        { distributorId }
      )
      logger.debug(data)
      try {
        dispatch({
          type: CREATE_OWNER_COMPLETE,
          payload: await createOwner(distributorId, data)
        })
        enqueueSnackbar(t('successInfo.createOwner'), { variant: 'success' })
      } catch (error) {
        handleApiError(error, 'createOwnerError')
      }
    },
    [dispatch, handleApiError]
  )

  const handleCreateShop = useCallback(
    async (ownerId, data) => {
      logger.info(`Creating shop '${data.name}' for owner '${ownerId}'`, {
        ownerId
      })
      logger.debug(data)
      try {
        dispatch({
          type: CREATE_SHOP_COMPLETE,
          payload: await createShop(ownerId, data)
        })
        enqueueSnackbar(t('successInfo.createShop'), { variant: 'success' })
      } catch (error) {
        if (error.error?.certificatePassword) {
          throw new ApiError(
            error.error,
            error.status,
            t(`errors.${error.error.certificatePassword}`)
          )
        }
        handleApiError(error, 'createShopError')
      }
    },
    [dispatch, handleApiError]
  )

  const handleDeleteShop = useCallback(
    async (shopId) => {
      logger.info(`Deleting shop '${shopId}' `, { shopId })
      try {
        dispatch({
          type: DELETE_SHOP_COMPLETE,
          payload: await deleteShop(shopId)
        })
        enqueueSnackbar(t('successInfo.shopDeletion'), { variant: 'success' })
      } catch (error) {
        handleApiError(error, 'shopDeletionError')
      }
    },
    [dispatch, handleApiError]
  )

  const handleFreeLicenses = useCallback(
    async (shopId) => {
      logger.info(`Free licenses in shop '${shopId}' `, { shopId })
      try {
        dispatch({
          type: FREE_SHOP_LICENSES,
          payload: await freeShopLicenses(shopId)
        })
        enqueueSnackbar(t('successInfo.freeLicense'), { variant: 'success' })
      } catch (error) {
        handleApiError(error, 'freeLicenseError')
      }
    },
    [dispatch, handleApiError]
  )

  const handleGenerateNewLicenses = useCallback(
    async (distributorId, data) => {
      logger.info(`Generate new licenses for distributor ${distributorId}' `, {
        distributorId
      })
      try {
        dispatch({
          type: GENERATE_NEW_LICENSES,
          payload: await generateNewLicenses(distributorId, data)
        })
        enqueueSnackbar(t('successInfo.generatedLicenses'), {
          variant: 'success'
        })
      } catch (error) {
        handleApiError(error, 'generatedLicensesError')
      }
    },
    [dispatch, handleApiError]
  )

  const handleEditShopCredentials = useCallback(async (shopId, data) => {
    logger.info(`Editing shop credentials with id '${shopId}'`, { shopId })
    logger.debug(data)
    try {
      await editShopCredentials(shopId, data)
      enqueueSnackbar(t('successInfo.shopCredentialsChange'), {
        variant: 'success'
      })
    } catch (error) {
      handleApiError(error, 'shopCredentialsError')
    }
  }, [handleApiError])

  const handleEditShop = useCallback(
    async (shopId, data) => {
      logger.info(`Editing shop with id '${shopId}'`, { shopId })
      logger.debug(data)
      try {
        dispatch({
          type: EDIT_SHOP_COMPLETE,
          payload: await editShop(shopId, data)
        })
        enqueueSnackbar(t('successInfo.editShop'), { variant: 'success' })
      } catch (error) {
        handleApiError(error, 'editShopError')
      }
    },
    [dispatch, handleApiError]
  )

  const handleCreateEmployee = useCallback(
    async (ownerId, data) => {
      logger.info(`Creating employee '${data.email}' for owner '${ownerId}'`, {
        ownerId
      })
      logger.debug(data)
      try {
        dispatch({
          type: CREATE_EMPLOYEE_COMPLETE,
          payload: await createEmployee(ownerId, data)
        })
        enqueueSnackbar(t('successInfo.createEmployee'), { variant: 'success' })
      } catch (error) {
        handleApiError(error, 'createEmployeeError')
      }
    },
    [dispatch, handleApiError]
  )

  const handleSetEmployeePassword = useCallback(async (employeeId, data) => {
    logger.info(`Resetting password for employee '${employeeId}'`, {
      employeeId
    })
    logger.debug(data)
    try {
      await setEmployeePassword(employeeId, data)
      enqueueSnackbar(t('successInfo.setEmployeePassword'), { variant: 'success' })
    } catch (error) {
      handleApiError(error, 'setEmployeePasswordError')
    }
  }, [handleApiError])

  const handleSendEmployeeActivationLink = useCallback(async (employeeId) => {
    logger.info(`Sending activation link for employee '${employeeId}'`, {
      employeeId
    })
    try {
      await sendEmployeeActivationLink(employeeId)
      enqueueSnackbar(t('successInfo.sendEmployeeActivationLink'), { variant: 'success' })
    } catch (error) {
      handleApiError(error, 'sendEmployeeActivationLinkError')
    }
  }, [handleApiError])

  const handleUploadCertificate = useCallback(
    async (id, data) => {
      logger.info(`Uploading new certificate for distributor '${id}'`, {
        distributorId: id
      })
      logger.debug(data)
      try {
        dispatch({
          type: CERTIFICATE_UPLOAD_COMPLETE,
          payload: { id, certificate: await uploadCertificate(id, data) }
        })
        enqueueSnackbar(t('successInfo.uploadCertificate'), { variant: 'success' })
      } catch (error) {
        if (error.error?.certificate) {
          throw new ApiError(
            error.error,
            error.status,
            t(`errors.${error.error.certificate}`)
          )
        }
        handleApiError(error, 'uploadCertificateError')
      }
    },
    [dispatch, handleApiError]
  )

  const handleUpdateCertificate = useCallback(
    async (id, data) => {
      logger.info(`Updating certificate for distributor '${id}'`, {
        distributorId: id
      })
      logger.debug(data)
      try {
        dispatch({
          type: CERTIFICATE_UPDATE_COMPLETE,
          payload: { id, certificate: await updateCertificate(id, data) }
        })
        enqueueSnackbar(t('successInfo.updateCertificate'), { variant: 'success' })
      } catch (error) {
        if (error.error?.certificate) {
          throw new ApiError(
            error.error,
            error.status,
            t(`errors.${error.error.certificate}`)
          )
        }
        handleApiError(error, 'updateCertificateError')
      }
    },
    [dispatch, handleApiError]
  )

  const handleLockShop = useCallback(
    async (shopId, data) => {
      logger.info(`${data.locked ? 'Lock' : 'Unlock'} shop '${shopId}' `, {
        shopId
      })

      try {
        dispatch({
          type: LOCK_SHOP_COMPLETE,
          payload: await lockShop(shopId, data)
        })
        data.locked
          ? enqueueSnackbar(t('successInfo.shopLock'), { variant: 'success' })
          : enqueueSnackbar(t('successInfo.shopUnlock'), { variant: 'success' })
      } catch (error) {
        handleApiError(error, data.locked ? 'lockShopError' : 'unlockShopError')
      }
    },
    [dispatch, handleApiError]
  )

  const handleConfigureSocialConcept = useCallback(
    async (shopId, data) => {
      logger.info(`${data.socialConceptEnabled ? 'Enable' : 'Disable'} Social Concept for shop '${shopId}' `, {
        shopId
      })

      try {
        dispatch({
          type: CONFIGURE_SOCIAL_CONCEPT_COMPLETE,
          payload: await configureSocialConcept(shopId, data)
        })
        data.socialConceptEnabled
          ? enqueueSnackbar(t('successInfo.skEnabled'), { variant: 'success' })
          : enqueueSnackbar(t('successInfo.skDisabled'), { variant: 'success' })
      } catch (error) {
        handleApiError(error, 'configureSKError')
      }
    },
    [dispatch, handleApiError]
  )

  const handleUpdateCountingFunction = useCallback(
    async (userId, data) => {
      logger.info(`Updating features for user with id '${userId}'`, { userId })

      try {
        dispatch({
          type: UPDATE_DISTRIBUTOR_FEATURES,
          payload: await updateUserFeature(userId, data)
        })
        isFeatureActivated(data.features, UserFeatures.OasisApiCounting)
          ? enqueueSnackbar(t('successInfo.featureActivated'), { variant: 'success' })
          : enqueueSnackbar(t('successInfo.featureDeactivated'), { variant: 'success' })
      } catch (error) {
        handleApiError(error, 'featureUpdateError')
      }
    },
    [dispatch, handleApiError]
  )

  const handleConfigureSocialConceptTemplate = useCallback(
    async (entityType, entityId, data) => {
      logger.info(`Configure Social Concept Template for ${entityType} '${entityId}'`, {
        entityId, entityType
      })

      try {
        await setSocialConceptTemplateAttributes(entityType, entityId, data)
        enqueueSnackbar(t('successInfo.skTemplateConfigured'), { variant: 'success' })
      } catch (error) {
        handleApiError(error, 'configureSKTemplateError')
      }
    },
    [handleApiError, enqueueSnackbar, t]
  )

  return (
    <>
      {ready && hasCertificate
        ? (
          <>
            <Layout header={Header} content={DistributorContent} />
            <Routes>
              <Route
                path='/employee/:id/*'
                element={
                  <EmployeeRoutes
                    handleSetEmployeePassword={handleSetEmployeePassword}
                    handleSendEmployeeActivationLink={
                      handleSendEmployeeActivationLink
                    }
                  />
                }
              />
              <Route
                path='/shop/:id/*'
                element={
                  <ShopRoutes
                    handleAssignLicensesToShop={handleAssignLicensesToShop}
                    handleFreeLicenses={handleFreeLicenses}
                    handleEditShopCredentials={handleEditShopCredentials}
                    handleEditShop={handleEditShop}
                    handleAssignEmployeesToShop={handleAssignEmployeesToShop}
                    handleLockShop={handleLockShop}
                    handleConfigureSocialConcept={handleConfigureSocialConcept}
                    handleConfigureSocialConceptTemplate={handleConfigureSocialConceptTemplate}
                  />
                }
              />
              <Route
                path='/owner/:id/*'
                element={
                  <OwnerRoutes
                    handleAssignLicensesToOwner={handleAssignLicensesToOwner}
                    handleDeleteShop={handleDeleteShop}
                    handleCreateShop={handleCreateShop}
                    handleCreateEmployee={handleCreateEmployee}
                    handleConfigureSocialConceptTemplate={handleConfigureSocialConceptTemplate}
                  />
                }
              />
              <Route
                path='/distributor/:id/*'
                element={
                  <DistributorRoutes
                    handleUpdateCertificate={handleUpdateCertificate}
                    handleGenerateNewLicenses={handleGenerateNewLicenses}
                    handleUpdateCountingFunction={handleUpdateCountingFunction}
                    handleConfigureSocialConceptTemplate={handleConfigureSocialConceptTemplate}
                  />
                }
              />
            </Routes>
          </>)
        : (
          <Loading />
          )}
      <Routes>
        <Route
          path='/distributor/:id/*'
          element={
            <>
              <ModalRoute
                path='create-owner'
                onSubmit={handleCreateOwner}
                component={CreateOwner}
              />
              <ModalRoute
                path='upload-certificate'
                onSubmit={handleUploadCertificate}
                redirectTo={generatePath(privateRoutes.createOwner, { id })}
                component={UploadDistributorCertificate}
              />
            </>
          }
        />
      </Routes>
    </>
  )
}

Distributor.propTypes = {
  id: PropTypes.string.isRequired
}

export default Distributor
