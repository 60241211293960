import PropTypes from 'prop-types'
import React from 'react'
import ModalScreen from '../../components/modals/modalComponents/ModalScreen'
import { numberInput } from '../../components/OasisForm/fields'
import { fieldsToForm } from '../../components/OasisForm/helpers'
import ModalForm from './form/ModalForm'
import { useTranslation } from 'react-i18next'
import { useDistributor } from '../../components/distributor/hooks'

const fields = {
  count: { ...numberInput, label: 'fieldLabelNumberOfLicenses' }
}

const emptyForm = fieldsToForm(fields)

const GenerateNewLicenses = ({ onCancel = () => {}, onSubmit = () => {} }) => {
  const distributor = useDistributor()
  const { t } = useTranslation('oasisBackoffice')

  return (
    <ModalScreen
      openModal
      onClose={onCancel}
      title={t('actionDialogs.generateNewLicenses', { distributor: distributor.name })}
      withForm
    >
      <ModalForm
        fields={fields}
        defaultValue={emptyForm}
        onSubmit={onSubmit}
        onCancel={onCancel}
        submitLabel='buttonLabelSubmit'
      />
    </ModalScreen>
  )
}

GenerateNewLicenses.propTypes = {
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func
}

export default GenerateNewLicenses
