import PropTypes from 'prop-types'
import React from 'react'
import ModalScreen from '../../components/modals/modalComponents/ModalScreen'
import {
  nameInput,
  passwordInput,
  shopLocationSelect,
  shopTypeSelect,
  shopScheduleSelect,
  phoneInput,
  emailInput
} from '../../components/OasisForm/fields'
import { fieldsToForm } from '../../components/OasisForm/helpers'
import ModalForm from './form/ModalForm'
import { useTranslation } from 'react-i18next'
import { useBackOffice } from '../hooks/useBackOffice'
import useAuth from '../../hooks/useAuth'
import { UserRoles } from '../const'
import { getScheduleDropdownItems } from './helpers'

const CreateShop = ({ onCancel = () => {}, onSubmit = () => {} }) => {
  const { t } = useTranslation('oasisBackoffice')
  const [state] = useBackOffice()

  const defaultValue = {
    type: shopTypeSelect.default,
    location: shopLocationSelect.default,
    schedule: state.schedules.ids?.[0] ?? null
  }

  const {
    user: { userRole }
  } = useAuth()

  const fields = {
    name: nameInput,
    type: {
      ...shopTypeSelect,
      items: state.metaData.types.map((type) => ({
        value: type.id,
        label: type.label
      }))
    },
    location: {
      ...shopLocationSelect,
      items: state.metaData.locations.map((location) => ({
        value: location.id,
        label: location.label
      }))
    },
    email: emailInput,
    phoneNumber: phoneInput,
    certificateUsername: { ...nameInput, label: 'fieldLabelOasisUserName' },
    certificatePassword: { ...passwordInput, label: 'fieldLabelOasisPassword' },
    schedule: {
      ...shopScheduleSelect,
      disabled: userRole !== UserRoles.Admin,
      items: getScheduleDropdownItems(state.schedules)
    }
  }

  const formWithDefaults = fieldsToForm(fields, defaultValue)

  return (
    <ModalScreen
      openModal
      onClose={onCancel}
      title={t('actionDialogs.titleCreateShop')}
      withForm
    >
      <ModalForm
        fields={fields}
        defaultValue={formWithDefaults}
        onSubmit={onSubmit}
        onCancel={onCancel}
        submitLabel='buttonLabelCreate'
      />
    </ModalScreen>
  )
}
CreateShop.propTypes = {
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func
}

export default CreateShop
