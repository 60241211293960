export const FieldTypes = {
  EMAIL: 'email',
  FILE: 'file',
  PASSWORD: 'password',
  RECAPTCHA: 'recaptcha',
  TEXT: 'text',
  NUMBER: 'number',
  PHONE: 'phone'
}

export const nameInput = {
  label: 'fieldLabelName',
  type: FieldTypes.TEXT,
  required: true
}

export const numberInput = {
  label: 'fieldLabelNumber',
  type: FieldTypes.NUMBER,
  min: 1,
  max: 100
}

export const passwordInput = {
  label: 'fieldLabelPassword',
  type: FieldTypes.PASSWORD,
  required: true,
  InputProps: {
    autoComplete: 'new-password'
  }
}

export const confirmPasswordInput = {
  label: 'fieldLabelConfirmPassword',
  type: FieldTypes.PASSWORD,
  required: true,
  InputProps: {
    autoComplete: 'new-password'
  },
  match: 'password'
}

export const fileInput = {
  label: 'fieldLabelFile',
  type: FieldTypes.FILE
}

export const emailInput = {
  label: 'fieldLabelEmail',
  type: FieldTypes.EMAIL
}

export const phoneInput = {
  label: 'fieldLabelPhone',
  type: FieldTypes.PHONE,
  defaultCountry: 'de',
  regions: 'europe'
}

export const reCaptchaField = {
  type: FieldTypes.RECAPTCHA,
  required: true
}

export const contactInfoTextArea = {
  label: 'fieldLabelContactInfo',
  type: FieldTypes.TEXT,
  multiline: true,
  maxRows: 3,
  minRows: 3
}
export const shopTypeSelect = {
  label: 'fieldLabelShopType',
  type: FieldTypes.TEXT,
  // The select prop makes the text field use the Select component internally.
  select: true,
  items: [],
  default: 0,
  required: true
}
export const shopLocationSelect = {
  label: 'fieldLabelShopLocation',
  type: FieldTypes.TEXT,
  // The select prop makes the text field use the Select component internally.
  select: true,
  items: [],
  default: 0,
  required: true
}

export const shopScheduleSelect = {
  label: 'fieldLabelShopScheduleSelect',
  type: FieldTypes.TEXT,
  select: true,
  default: 16,
  items: []
}
