import { useMemo } from 'react'
import useNavigateTo from '../../hooks/useNavigateTo'
import privateRoutes from '../../private/routes'
import { useBackOffice } from '../../private/hooks/useBackOffice'
import useAuth from '../../hooks/useAuth'
import { sortBy } from 'lodash'
import { useLocation } from 'react-router-dom'
import { UserFeatures, UserRoles } from '../../private/const'
import { isFeatureActivated } from '../../private/featureHelpers'

export const useDistributorActions = (id) => {
  const navigateTo = useNavigateTo()
  const licenses = useDistributorLicenses(id)
  const { logout, user: { userRole } } = useAuth()
  const location = useLocation()
  const distributor = useDistributor()
  const distributorUserFeatures = distributor?.users?.[0]?.features || 0

  return useMemo(
    () => {
      const unassignedLicensesCount = licenses
        .filter(({ owner }) => !owner)
        .map(({ distributor }) => distributor === id)
        .length

      const actions = [
        {
          label: 'buttonLabelUpdateCert',
          handleOnClick: () => navigateTo(privateRoutes.updateCertificate, { id })
        },
        {
          label: 'btnLabelCreateOwner',
          handleOnClick: () => navigateTo(privateRoutes.createOwner, { id }),
          notification: 'notification',
          unassignedLicensesCount
        },
        {
          label: 'btnLabelLogOut',
          handleOnClick: logout
        }
      ]

      // Add template action only for admin with DigitalSocialConcept feature
      if (userRole === UserRoles.Admin &&
          isFeatureActivated(distributorUserFeatures, UserFeatures.DigitalSocialConcept)) {
        // Insert before logout button
        actions.splice(actions.length - 1, 0, {
          label: 'btnLabelConfigureSKTemplate',
          handleOnClick: () => navigateTo(privateRoutes.configureSocialConceptTemplateDistributor, { id })
        })
      }

      return actions
    },
    [id, licenses, location, userRole, distributorUserFeatures, navigateTo, logout]
  )
}

export const useDistributor = () => {
  const [state] = useBackOffice()

  return state.distributors.byId[state.distributor]
}

export const useDistributorSelect = () => {
  const [state] = useBackOffice()

  return useMemo(() => {
    const distributors = Object.entries(state.distributors.byId).map(([key, { name, id, certificate }]) => ({ name, id, missingCertificate: !certificate }))
    const sortedDistributors = sortBy(distributors, ({ name }) => name)

    return {
      options: sortedDistributors,
      selected: state.distributor
    }
  }, [state.distributors.byId, state.distributor])
}

export const useDistributorLicenses = (id) => {
  const [state] = useBackOffice()

  return useMemo(
    () => {
      // Add null checks
      if (!state.licenses || !state.licenses.ids) {
        return []
      }

      return state.licenses.ids
        .filter((licenseId) => state.licenses.byId[licenseId].distributor === id)
        .map((licenseId) => state.licenses.byId[licenseId])
    },
    [id, state.licenses]
  )
}
