import React from 'react'
import { Route, Routes } from 'react-router-dom'
import ModalRoute from '../../private/ModalRoute'
import AssignLicensesToShop from '../modals/AssignLicenses/AssignLicensesToShop'
import AssignEmployeesModal from '../../private/actionDialogs/assignEmployees/AssignEmployeesModal'
import FreeLicense from '../../private/actionDialogs/FreeLicense'
import EditShopCredentials from '../../private/actionDialogs/EditShopCredentials'
import EditShop from '../../private/actionDialogs/EditShop'
import LockShop from '../../private/actionDialogs/LockShop'
import OasisApiCallErrorsModal from '../../private/actionDialogs/OasisApiCallErrors/OasisApiCallErrorsModal'
import ConfigureSocialConcept from '../../private/actionDialogs/ConfigureSocialConcept'
import ConfigureSocialConceptTemplate from '../../private/actionDialogs/ConfigureSocialConceptTemplate'
import AuthorizedView from '../common/AuthorizedView'
import { AvailableFeatures } from '../../private/const'

const ShopRoutes = ({
  handleAssignLicensesToShop,
  handleFreeLicenses,
  handleEditShopCredentials,
  handleEditShop,
  handleAssignEmployeesToShop,
  handleLockShop,
  handleConfigureSocialConcept,
  handleConfigureSocialConceptTemplate
}) => (
  <Routes>
    <Route
      path='*'
      element={
        <>
          <ModalRoute
            path='assign-employees'
            onSubmit={handleAssignEmployeesToShop}
            component={AssignEmployeesModal}
          />
          <ModalRoute
            path='assign-licenses'
            onSubmit={handleAssignLicensesToShop}
            component={AssignLicensesToShop}
          />
          <ModalRoute
            path='free-license'
            onSubmit={handleFreeLicenses}
            component={FreeLicense}
          />
          <ModalRoute
            path='edit-shop-credentials'
            onSubmit={handleEditShopCredentials}
            component={EditShopCredentials}
          />
          <ModalRoute
            path='edit-shop'
            onSubmit={handleEditShop}
            component={EditShop}
          />
          <AuthorizedView>
            <ModalRoute
              path='lock'
              onSubmit={handleLockShop}
              component={LockShop}
            />
          </AuthorizedView>
          <AuthorizedView features={[AvailableFeatures.oasisApiCounting]}>
            <ModalRoute
              path='oasis-api-errors/:employeeId'
              onSubmit={() => null}
              component={OasisApiCallErrorsModal}
              cancellable
            />
          </AuthorizedView>

          <AuthorizedView features={[AvailableFeatures.digitalSocialConcept]}>
            <ModalRoute
              path='configure-social-concept'
              onSubmit={handleConfigureSocialConcept}
              component={ConfigureSocialConcept}
            />
          </AuthorizedView>

          <AuthorizedView features={[AvailableFeatures.digitalSocialConcept]}>
            <ModalRoute
              path='configure-social-concept-template'
              onSubmit={(id, data) => handleConfigureSocialConceptTemplate('shop', id, data)}
              component={(props) => <ConfigureSocialConceptTemplate {...props} entityType='shop' />}
            />
          </AuthorizedView>
        </>
      }
    />
  </Routes>
)

export default ShopRoutes
