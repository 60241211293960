import PropTypes from 'prop-types'
import React from 'react'
import { Route, Routes, useParams } from 'react-router-dom'
import useNavigateTo from '../hooks/useNavigateTo'
import useErrorHandler from '../hooks/useErrorHandler'
import privateRoutes from './routes'
import { isConflictErrorCode } from '../helpers'

const ModalRoute = ({
  redirectTo,
  component: Component,
  onSubmit = () => {},
  onCancel = () => {},
  cancellable = true,
  ...props
}) => {
  const navigateTo = useNavigateTo()
  const handleError = useErrorHandler()
  const handleRedirect = () => navigateTo(redirectTo || privateRoutes.root)

  const { id } = useParams()

  const handleOnCancel = () => cancellable && handleRedirect()

  const handleOnSubmit = async (...args) => {
    try {
      await onSubmit(id, ...args)
      handleRedirect()
    } catch (error) {
      if (isConflictErrorCode(error.status)) {
        handleError(error)
      } else {
        throw error
      }
    }
  }

  return (
    <Routes>
      <Route
        {...props}
        element={
          <Component
            onCancel={handleOnCancel}
            onSubmit={handleOnSubmit}
          />
        }
      />
    </Routes>
  )
}

ModalRoute.propTypes = {
  path: PropTypes.string.isRequired,
  component: PropTypes.elementType.isRequired,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  cancellable: PropTypes.bool
}

export default ModalRoute
